import React from "react";
import { useTranslation } from "react-i18next";
import { Card } from "clutch/src/Card/Card.jsx";

import { getCurrentSet, TFTRounds } from "@/game-tft/constants.mjs";
import { ContentContainer } from "@/game-tft/GuideRounds.style.jsx";
import { URLS } from "@/game-tft/guides-constants.mjs";
import Swords from "@/inline-assets/hextech-stats-melee.svg";
import PageContainer from "@/shared/PageContainer.jsx";
import PageHeader from "@/shared/PageHeader.jsx";

const ROUNDS = Object.entries(TFTRounds).map(([roundNum, round]) => ({
  num: Number(roundNum),
  title: round.title,
  stages: round.stages.map((round) => ({
    ...round,
    display: `${roundNum} - ${round.num}`,
  })),
}));

const Rounds = () => {
  const { t } = useTranslation();

  return (
    <PageContainer>
      <PageHeader
        title={t("tft:guidesRounds", "Round Stages - Guides")}
        links={URLS}
      />
      <ContentContainer>
        {ROUNDS.map((round) => (
          <Card key={round.num} padding="0" className="stage">
            <header>
              <span className="type-caption--bold">
                {t("tft:round", "Round")}
              </span>
              <span className="type-h5">
                {round.num === 5
                  ? t("tft:roundDefinitions.fiveToEight", "5-8")
                  : round.num === 9
                    ? t("tft:roundDefinitions.ninePlus", "9+")
                    : round.num}
              </span>
            </header>
            <div className="body">
              <ol className="rounds">
                {round.stages.map((stage) => (
                  <li key={stage.num} className="round">
                    {stage.url ? (
                      <img src={stage.url} width="36" height="36" />
                    ) : (
                      <Swords />
                    )}
                    <span className="display type-caption--bold">
                      {stage.display}
                    </span>
                    <span className="name type-caption--bold">
                      {stage.name}
                    </span>
                  </li>
                ))}
              </ol>
              {round.title && (
                <span className="description type-caption">
                  {t(...round.title)}
                </span>
              )}
            </div>
          </Card>
        ))}
      </ContentContainer>
    </PageContainer>
  );
};

export default Rounds;

export function meta(_params, searchParams) {
  let set = searchParams?.get("set") || getCurrentSet().replace("set", "");
  set = set.replace("_", ".");
  return {
    title: [
      "tft:meta.rounds.title",
      "TFT Round Stages - TFT Guides - Set {{set}}",
      { set },
    ],
    description: [
      "tft:meta.rounds.description",
      "Unlock Winning Strategies with Round Stages in TFT! Optimize Comps, Builds, and Meta Using Key Stats.",
      { set },
    ],
  };
}
