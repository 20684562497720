import { styled } from "goober";

export const ContentContainer = styled("div")`
  display: grid;
  grid-auto-rows: auto;
  gap: var(--sp-4);

  .stage {
    display: flex;

    @container content-card (width <= 575px) {
      flex-direction: column;
    }

    header {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 6.5rem;
      padding: var(--sp-3) var(--sp-6);
      background: var(--shade7);
      border-top-left-radius: var(--br-lg);
      border-bottom-left-radius: var(--br-lg);
      border-right: 1px solid var(--shade3-25);

      @container content-card (width <= 575px) {
        width: unset;
        border-right: unset;
        border-bottom-left-radius: unset;
        border-top-right-radius: var(--br-lg);
        border-bottom: 1px solid var(--shade3-25);
      }
    }

    .body {
      --img-size: var(--sp-12);
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: var(--sp-4);
      padding: var(--sp-6);

      @container content-card (width <= 750px) {
        flex-direction: column;
        --img-size: var(--sp-11);
      }
    }

    .rounds {
      --gap: var(--sp-4);
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      gap: var(--gap);
    }
    .round,
    .round img {
      width: var(--img-size);
      height: auto;
      aspect-ratio: 1;
      border-radius: 50%;
    }
    .round {
      --border-color: var(--shade6);
      position: relative;
      display: grid;
      place-content: center;
      background: var(--shade8);
      box-shadow: 0 0 0 2px var(--border-color);

      &:hover {
        filter: brightness(1.4);

        &::after {
          filter: brightness(0.6);
        }

        .display,
        .name {
          opacity: 1;
        }
        .display {
          translate: -50% -110%;
        }
        .name {
          translate: -50% 110%;
        }
      }

      svg {
        width: var(--sp-6);
        height: var(--sp-6);
        fill: var(--shade2);
      }
      .display,
      .name {
        position: absolute;
        left: 50%;
        opacity: 0;
        white-space: nowrap;
        translate: -50% 0%;
        transition:
          opacity 0.15s ease-out,
          translate 0.15s ease-out;
      }
      .display {
        top: 0;
        translate: -50% -50%;
      }
      .name {
        bottom: 0;
        translate: -50% 50%;
      }

      &:not(:last-child) {
        &::after {
          content: "";
          position: absolute;
          top: 50%;
          left: 100%;
          width: var(--gap);
          height: 2px;
          background: var(--border-color);
          translate: 0 -50%;
        }
      }
    }

    .description {
      text-align: right;
      color: var(--shade1);

      span {
        color: var(--shade3);
        padding-inline: var(--sp-1);
      }

      @container content-card (width <= 750px) {
        text-align: center;
      }
    }
  }
`;
